import * as React from 'react';
import { Route, Routes } from "react-router-dom"
import BodyApp from './BodyApp';
import LoginApp from './Login';
import AppProt from './Prot';
import AppLog from './Log';
import AppUsers from './Users';
import AppSSE from './SSE';

function App() {
  return (
    <div>
      <BodyApp />            
      <Routes>
        <Route path="/" element={<LoginApp />} /> 
        <Route path="/login" element={<LoginApp />} />         
        <Route path="/prot" element={<AppProt />} />
        <Route path="/logs" element={<AppLog />} />        
        <Route path="/users" element={<AppUsers />} />        
        <Route path="/sse" element={<AppSSE />} />                
      </Routes>      
    </div>
  );
}

export default App;
