import { createSlice } from '@reduxjs/toolkit';


const userSlice = createSlice({
  name: 'user',
  initialState:{
    user:'',
    password:'',
    level:'',
    blogin:false,
    status:'',
    bLogSlice:false,
    bProtSlice:false,
    bUsersSlice:false,
    clientUUID:'',
    serverUUID:'',  
    IP:'',  
  },
  // Редьюсеры в слайсах меняют состояние и ничего не возвращают
  reducers: {
    // Пример с данными
    setUserParams(state, action)  {
      
      state.user = action.payload;
    },
    setPasswordParams(state, action)  {
      
      state.password = action.payload;
    },
    setLevelParams(state, action)  {

      state.level = action.payload;
    },

    clearUserParams(state)  {
      state.user ='';
    },
    clearPasswordParams(state)  {
      state.passsword ='';
    },
    setLoginParams(state,action)  {
      state.blogin =action.payload;
    },
    setStatusParams(state,action)  {
      state.status =action.payload;
    },
    setClientParams(state,action)  {
      state.clientUUID =action.payload;
    },
    setServerParams(state,action)  {
      state.serverUUID =action.payload;
    },
    setLogReducer(state,action)  {
      state.bLogSlice =action.payload;
    },
    setProtReducer(state,action)  {
      state.bProtSlice =action.payload;
    },
    setUsersReducer(state,action)  {
      state.bUsersSlice =action.payload;
    },
    setIPParams(state,action)  {
      state.IP =action.payload;
    },
  },
});

// По умолчанию экспортируется редьюсер, сгенерированный слайсом
export default userSlice.reducer;

// Слайс генерирует действия, которые экспортируются отдельно
// Действия генерируются автоматически из имен ключей редьюсеров
export const {  setUserParams,setPasswordParams,clearUserParams, clearPasswordParams, 
                setLoginParams, setStatusParams, setLogReducer,setProtReducer, setUsersReducer, setLevelParams,
                setClientParams,setServerParams,setIPParams } = userSlice.actions;

