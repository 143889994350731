import { createSlice } from '@reduxjs/toolkit';

// Начальное значение
const initialState = { 
  addres:'https://serhio.su',
  port:4000,  
};

const serverSlice = createSlice({
  name: 'server',
  initialState,
  // Редьюсеры в слайсах меняют состояние и ничего не возвращают
  reducers: {
    // Пример с данными
    setServerParams(state, action)  {
      console.log('setServerParams %s',action.payload);
      state.addres = action.payload.addres;
      state.port = action.payload.port;
    },
    clearServerParams(state)  {
      state.addres ='';
      state.port =0;
    },
  },
});

// По умолчанию экспортируется редьюсер, сгенерированный слайсом
export default serverSlice.reducer;

// Слайс генерирует действия, которые экспортируются отдельно
// Действия генерируются автоматически из имен ключей редьюсеров
export const { setServerParams,clearServerParams } = serverSlice.actions;

