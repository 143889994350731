import React, {useState,useEffect, useRef} from 'react';
import axios from "axios";

import { Outlet, Link, useNavigate, useParams, RouterProvider, createBrowserRouter,} from "react-router-dom";

import ResponsiveAppBar from './myAppBar';
import ProtApp from './Prot';
import LoginApp from './Login';
import UsersApp from './Users';
import LogApp from './Log.js';

import { useSelector, useDispatch } from 'react-redux';
import { setLoginParams, setUserParams, setPasswordParams,setLogReducer, setProtReducer, setUsersReducer,setIPParams } from './slices/userSlice.js';

function BarApp(){
    const [username, setUsername] = useState('');        
    const [password, setPassword] = useState('');   
    const [status,setStatus] = useState('');

    const [bLogin,setLogin] = useState(false);    
    const [bProt,setProt] = useState(false);     
    const [bLog,setLog] = useState(false);         
    const [bUsers,setUsers] = useState(false);     
    const [uuidClient,setUuidClient] = useState('');    
    const [uuidServer,setUuidServer] = useState('');        
    
//    const intervalRef = React.useRef();

    const userRedux = useSelector( (state) =>state.user.user);
    const passwordRedux = useSelector( (state) =>state.user.password);    
    const loginRedux = useSelector( (state) =>state.user.blogin);    
    const statusRedux = useSelector( (state) =>state.user.status);        
    const levelRedux = useSelector( (state) =>state.user.lebel);        
    const bLogRedux = useSelector( (state) =>state.user.bLogSlice);    
    const bProtRedux = useSelector( (state) =>state.user.bProtSlice);    
    const bUsersRedux = useSelector( (state) =>state.user.bUsersSlice);     
    const serverRedux= useSelector( (state) =>state.server.addres);        
    const portRedux= useSelector( (state) =>state.server.port);
    const IPRedux= useSelector( (state) =>state.user.IP);

    const dispatch = useDispatch();

    const getIP = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        console.log(res.data);
        dispatch(setIPParams(res.data.ip));
    };

    useEffect(() => {
        getIP();

    }, []);

      const getTime = () => {
        let dt = new Date();        
        console.log('timer %s',dt);
      };


    
    const handleLogin = e =>{
        console.info('Login user=%s password=%s bLogin=%s bLogin=%s',userRedux, passwordRedux,bLogin);
        getLogin(userRedux,passwordRedux);           
    }

    const getLogin = async (user,pass) => {
        let uuid = crypto.randomUUID();        
        console.log('random= %s',uuid);
        setUuidClient(uuid);
        const body = JSON.stringify({user:user,password:pass,uuid:uuid});
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: body,
            //redirect: 'follow',            
        };    
        const addr = serverRedux+":"+portRedux+"/api/login";
        await fetch(addr,requestOptions)
//        await fetch("http://localhost:4000/api/login",requestOptions)        
       .then((response) => response.json())
       .then((data) => {          
         console.log(' data >>',data);
         if (data.rows>0){
            setLogin(true);
            dispatch(setLoginParams(true));
            setUuidClient(body.uuid);
            setUuidServer(data.uuid);            
         }else {
            dispatch(setLoginParams(false));            
            setStatus('Login error');
         }
       })      
       .catch((error) =>{
        console.log("Ошибка получения данных: %s", error);
        setStatus('No Network');         
       })
   };


    const hChangeUser = e =>{
        dispatch(setUserParams(e.target.value));        
        console.log("hchangeUser");
        setUsername(e.target.value);
        if( (username.length>0) || (password.length>0)) {
            setStatus('');
        }
    }     
    const hChangePassword = e =>{
        dispatch(setPasswordParams(e.target.value));                
        console.log("hChangePassword");        
        setPassword(e.target.value);        
        if( (username.length>0) || (password.length>0)) {
            setStatus('');
        }
    }         
    const hChangeLogin = e =>{        
        setLogin(false);
        setProt(false);   
        setUsers(false);
        setUsername('');
        setPassword('');
        setStatus('');     
        dispatch(setLoginParams(false));
        dispatch(setLogReducer(false));
        dispatch(setUsersReducer(false)); 
        dispatch(setProtReducer(false));               
        console.info('hChangeLogin user=%s password=%s bLogin=%s bLogin=%s',username, password,bLogin,bLogin.toString());        
    }     
    const hChangeProt = e =>{        
        dispatch(setLogReducer(false));        
        dispatch(setProtReducer(true));                    
        dispatch(setUsersReducer(false));        
        console.info('hChangeProt bProtRedux=%s',bProtRedux.toString());                
    }
    const hChangeUsers = e =>{
        dispatch(setLogReducer(false));        
        dispatch(setProtReducer(false));                    
        dispatch(setUsersReducer(true));        
        console.info('hChangeUsers bUsersRedux=%s',bUsersRedux.toString());                
    }
    const hChangeLog = e =>{       
        dispatch(setLogReducer(true)); 
        dispatch(setProtReducer(false)); 
        dispatch(setUsersReducer(false));                 
        console.info('hChangeLog bLogRedx=%s',bLogRedux.toString());                
    }
    
    return (
        <>
        <ResponsiveAppBar blogin={bLogin} hChangeLogin={hChangeLogin} hChangeProt={hChangeProt} hChangeUsers={hChangeUsers}  hChangeLog={hChangeLog}/>
        <Outlet />
        </>
    );
}
export default BarApp;