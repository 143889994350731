import React, { useState,useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import {Table, TableBody, TableCell,TableContainer,TableHead, TableRow, Paper,Box,TableFooter, } from '@mui/material';

function AppSSE(){
    const [realTimeData, setRealTimeData] = useState([]);
    const loginRedux = useSelector( (state) =>state.user.blogin);   
    const serverRedux= useSelector( (state) =>state.server.addres);        
    const portRedux= useSelector( (state) =>state.server.port);


    useEffect(() => {
            const addr = serverRedux+":"+portRedux+"/sse";     
            const eventSource = new EventSource(addr);

            eventSource.onmessage = (event) => {
                try{
                    const newData = JSON.parse(event.data);  
                    //console.log('получили (%s) %s %s', realTimeData.length, newData, realTimeData);               
                    setRealTimeData(realTimeData =>[newData,...realTimeData]);                    
                }   
                catch {                   
                    eventSource.close();                                
                }    
            };
            eventSource.onerror = (event) => {
                eventSource.close();            
            }
            eventSource.addEventListener("ping",(event)=>{
                console.log('addListner %s',event.data);
            })
        return ()=>{
            
            eventSource.close(); 
        };
        },[serverRedux,portRedux]);    


    if(!loginRedux) return <Navigate to="/" replace/>;                
    return (
        <Box sx={{ display:'flex', width: '100%',justifyContent: 'center', }}>   

          <Box sx={{
            display: 'flex',                      
            p: 1,
            m: 1,
            bgcolor: 'background.paper',
            borderRadius: 1,
            height:500,
          }}>
        <TableContainer component={Paper} >
            <Table size="small" >
                <TableHead>
                    <TableRow sx={{ border:0}}>
                        <TableCell width="140" >Data</TableCell>
                        <TableCell width ="700" align="left">Message</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {realTimeData.map((item,index) => (                      
                    <TableRow   
                        key={index} 
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >    
                        <TableCell component="th" scope="row">
                            {(new Date(item.data)).toLocaleString()}
                        </TableCell>                
                        <TableCell component="th" scope="row" >
                            {item.message}
                        </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    
                </TableFooter>
            </Table>
        </TableContainer>
       
        </Box>       
        
        </Box>        
    )
}
export default AppSSE;