import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import { green, pink } from '@mui/material/colors';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FolderIcon from '@mui/icons-material/Folder';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';

import AddHomeIcon from '@mui/icons-material/AddHome';
import { grey, } from '@mui/material/colors';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import NoteIcon from '@mui/icons-material/Note';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';

import ListItemIcon from '@mui/material/ListItemIcon';

import { useSelector, useDispatch } from 'react-redux';
import { Link,  } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { setLoginParams, setUserParams, setLevelParams, setPasswordParams,setLogReducer, setUsersReducer, setProtReducer} from './slices/userSlice';



const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
const main = ['Абоненты', 'Log', 'Протокол'];

function ResponsiveAppBar( props) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElMain, setAnchorElMain] = React.useState(null);  
  
  const loginRedux = useSelector( (state) =>state.user.blogin); 
  const levelRedux = useSelector( (state) =>state.user.level);
  const userRedux = useSelector( (state) =>state.user.user);  
  const IPRedux = useSelector( (state) =>state.user.IP);    

  const dispatch = useDispatch();   
  const navigate = useNavigate();  

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);

  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
   
  };
  const handleOpenMainMenu = (event) => {
    setAnchorElMain(event.currentTarget);
    
  };
  
  const handleCloseNavMenu = (event) => {
    setAnchorElNav(null);
    console.info('handleCloseNavMenu - %s',event.currentTarget.textContent);        

  };

  const handleCloseUserMenu = (event) => {
    setAnchorElUser(null);
  }
  const hCloseUserMenu = (event) =>{
      setAnchorElUser(null);

      dispatch(setPasswordParams(''));
      dispatch(setLoginParams(''));            
      dispatch(setLevelParams(''));
      dispatch(setLogReducer(false));
      dispatch(setUsersReducer(false));  
      dispatch(setProtReducer(false));            
    
    console.info('handleCloseUserMenu %s',event.currentTarget.textContent);            
  };

  const handleCloseMainMenu = (event) => {
    setAnchorElMain(null);
    console.info('handleCloseMainMenu - %s',event.currentTarget.textContent);           
      
  };
    
 console.info('Response App props.blogin=%s',props.blogin.toString());
 //setLog(props.blogin);

 return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>

        <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Main tooltip">
              <IconButton onClick={handleOpenMainMenu} sx={{ p: 0 }} ><AddHomeIcon sx={{ fontSize: 40 , color: grey[50] }}  />
              </IconButton>
              
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              
              anchorEl={anchorElMain}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElMain)}
              onClose={handleCloseMainMenu}
            >        
                          
              <MenuItem  disabled={!loginRedux} > 
                <ListItemIcon><LocalLibraryIcon fontSize="small" /></ListItemIcon>
                <Link to="/users" disabled={!loginRedux} onClick={handleCloseMainMenu}>Абоненты </Link> 
              </MenuItem>
              <MenuItem  disabled={!loginRedux} > 
                <ListItemIcon><NoteIcon fontSize="small" /></ListItemIcon>
                <Link to="/logs" disabled={!loginRedux} onClick={handleCloseMainMenu}> Log </Link> 
              </MenuItem>
              <MenuItem  disabled={!loginRedux} > 
                <ListItemIcon><PermIdentityIcon fontSize="small" /></ListItemIcon>
                <Link to="/prot" disabled={!loginRedux} onClick={handleCloseMainMenu}> Протокол </Link> 
              </MenuItem>

            </Menu>
          </Box>



          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
        
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} disabled={!loginRedux} onClick={handleCloseNavMenu}>
                  <Typography key={page} textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            LOGO
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                disabled={!loginRedux}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt="Serhio" sx= { loginRedux ?  { bgcolor: green[500] } :{ bgcolor: pink[1] } } >
                {loginRedux? <AssignmentIcon /> :<FolderIcon />}
              </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem  disabled={!loginRedux} component={Link} to="/" onClick={handleCloseUserMenu}  > 
                <ListItemIcon><LocalLibraryIcon fontSize="small" /></ListItemIcon> Профиль 
              </MenuItem>
              <MenuItem  disabled={!loginRedux} component={Link} to="/" onClick={handleCloseUserMenu}> 
                <ListItemIcon><NoteIcon fontSize="small" /></ListItemIcon>Account </MenuItem>
              <MenuItem  disabled={!loginRedux} component={Link} to="/sse" onClick={handleCloseUserMenu} > 
                <ListItemIcon><PermIdentityIcon fontSize="small" /></ListItemIcon>SSE
              </MenuItem>
              <MenuItem  disabled={!loginRedux} component={Link} to="/" onClick={hCloseUserMenu}> 
                <ListItemIcon><PermIdentityIcon fontSize="small" /></ListItemIcon>Logout
              </MenuItem>
            </Menu>
          </Box>
          <Box sx={{p:1, boxShadow: 0,fontStyle:'italic'}}>
            <Box sx={{fontStyle:'normal'}}>{ IPRedux!=='' &&  IPRedux }</Box>
            <Box>{ loginRedux && ( <Typography  >  Пользователь: {userRedux} ( уровень:{levelRedux}  )</Typography> ) }</Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;