import React ,{useState,useRef} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { useSelector, useDispatch } from 'react-redux';
import { setUserParams, setLoginParams, setPasswordParams,setLevelParams,setClientParams,setServerParams, } from './slices/userSlice.js';

function LoginApp(){

    const userRedux = useSelector( (state) =>state.user.user);
    const loginRedux = useSelector( (state) =>state.user.blogin);    
    const passwordRedux = useSelector( (state) =>state.user.password);    
    const statusRedux = useSelector( (state) =>state.user.status);        
    const serverRedux= useSelector( (state) =>state.server.addres);        
    const portRedux= useSelector( (state) =>state.server.port);

    //console.log("serverRedux=%s portRedux=%s",serverRedux,portRedux);


    const dispatch = useDispatch();
     
    const [status,setStatus] = useState(statusRedux);
    const [heighBox,setWidthBox] = useState(150);    
    const textFieldRef = useRef();

    
    function changeUser(e) {
        dispatch(setUserParams(e.target.value));      
        setStatus('');  
        setWidthBox(150);
        console.log("Login changeUser %s",e.target.value);
    }     

    function changePassword(e) {
        dispatch(setPasswordParams(e.target.value));        
        setStatus('');   
        setWidthBox(150);               
        console.log("Login changePassword %s",e.target.value);
    }     
    const handleLogin = e =>{
        getLogin();           
    }

    const getLogin = async () => {
        
        const iv = window.crypto.getRandomValues(new Uint8Array(16)); 
        console.log('iv=%s',iv);  
        
        const ivbase64 = btoa(String.fromCharCode.apply(null,iv));
        console.log('ivbase64=%s',ivbase64);      
        
        const uuid = ivbase64;
 
        const body = JSON.stringify({user:userRedux,password:passwordRedux,uuid:uuid});
        const myHeaders = new Headers(); 

        myHeaders.append('Content-Type', 'application/json');        

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            mode:'cors',
            credentials:'omit',
            body: body,
        };    

        const addr = serverRedux+":"+portRedux+"/api/login";        
        //await fetch("http://localhost:4000/api/login",requestOptions)
        await fetch(addr,requestOptions)        
            .then((response) => response.json())
            .then((data) => {          
                console.log(' data >>',data);
                if (data.rows>0){
                    dispatch(setLoginParams(true));
                    console.log('datarows=%s ', JSON.stringify(data.datarows));
                    console.log('datarowslevel=%s ', data.datarows[0].level);            
                    dispatch(setLevelParams(data.datarows[0].level));
                    dispatch(setClientParams(uuid));
                    dispatch(setServerParams(data.uuid));            
                }else {
                    dispatch(setLoginParams(false)); 
                    dispatch(setPasswordParams(''));   
                    dispatch(setLevelParams('')); 
                    textFieldRef.current.value = '';                   
                    setWidthBox(220);            
                    setStatus('Login error');
                }
            })      
            .catch((error) =>{
                console.log("Ошибка получения данных: %s", error);
                setStatus('No Network');         
                dispatch(setPasswordParams('')); 
                setWidthBox(220);                                              
            })
   };

   if(loginRedux) return;

    return (
    <div class="center">
          <Box  sx={{m:'4rem', height:{heighBox}, width:300,mx:'auto', borderRadius:2, p:3, border:'1px solid',borderColor:'blue'}}>
            <Grid container spacing={1} direction={'column'} justify={'center'}  alignItems={'center'}>
                <Grid item >
                    <TextField  label="Username" error={status !==''} helperText={status!=='' ? status :""} Value={userRedux} onChange={changeUser} />
                </Grid>
                <Grid item >
                    <TextField label="Password" error={status !==''} helperText={status!=='' ? status :"" }  inputRef={textFieldRef}   onChange={changePassword} />
                </Grid>
                <Grid item >
                    <Button  fullWidth={Boolean(true)}  variant="contained" onClick={handleLogin}>Login</Button>
                </Grid>

            </Grid>
        </Box>
    </div>
    );
}

export default LoginApp;    
