  import React, {useRef, useEffect, useState} from 'react';
  import Box from '@mui/material/Box';
  import { Button, Alert, Stack, CircularProgress, ButtonGroup } from '@mui/material';
import { DataGrid,} from '@mui/x-data-grid';

import EditUser from './EditUser.js';

import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { setLoginParams, } from './slices/userSlice.js';
import { useSelector, useDispatch} from 'react-redux';


export default function AppUsers() {
  const loginRedux = useSelector( (state) =>state.user.blogin);   
  const dispatch = useDispatch();
  const clientUUIDRedux = useSelector( (state) =>state.user.clientUUID);  
  const serverUUIDRedux = useSelector( (state) =>state.user.serverUUID);          
  const navigate = useNavigate();      
  const serverRedux= useSelector( (state) =>state.server.addres);        
  const portRedux= useSelector( (state) =>state.server.port);


    const [users, setUsers] = React.useState([]);
    const [bWait,setWait] = React.useState(true);
    const [message, setMessage] = React.useState('');    
    const [bEditUser,setEditUser] = React.useState(false);
    const [recUser,setRecUser] = React.useState({});      
    const [typeSQL,setTypeSQL] = React.useState('update');

    const [colDefs,setColDefs]=React.useState([
      { field: 'num', headerName: '№', width: 30, editable:'false', type:'number', },
      { field: 'id', headerName: 'ID', width: 40, editable:'false', type:'number', },      
      { field: 'state', headerName: 'Состояние', width: 100, editable: false, type:'number',},
      { field: 'login', headerName: 'Login', width: 100, editable: false, type:'string',},
      { field: 'password', headerName: 'password', width: 100,editable: false, type:'string',},
      { field: 'name', headerName: 'Позывной', description: 'This', sortable: false, width: 150, type:'string',},
      { field: 'status',headerName: 'Статус', width: 100, editable: false, type:'number',},
      { field: 'last_login',headerName: 'Время входа',headerAlign: 'center',  width: 180, editable: false,
          valueGetter: (value, row) => {
            if(value===null) return '';
            let  d = new Date(row.last_login);
            d = d.toLocaleString(d);
            return d;    
          }
      }, 
      { field: 'last_logout',headerName: 'Время выхода',headerAlign: 'center', width: 180, editable: false,
            valueGetter: (value, row) => {
              if(value===null) return '';
              let  d = new Date(row.last_logout);
              d = d.toLocaleString(d);
              return d;    
            }    
      },
      { field: 'profit',headerName: 'Длительность',headerAlign: 'center', width: 180, editable: false,        
            valueGetter: (value, row) => {
              if((row.last_login===null) || (row.last_logout===null)) return '';              
              const  d1 = new Date(row.last_login);
              const  d2 = new Date(row.last_logout);
              const differentRes = new Date(d2-d1);  
              const minuts = differentRes.getMinutes();    
              const secs = differentRes.getSeconds();      
              return minuts+':'+secs;        
          }    
      },        
      { field: 'last_ip', headerName: 'IP', headerAlign: 'center',  width: 200, editable: false,},
      { field: 'Actions', headerName: '', headerAlign: 'center',  width: 120,editable: false,
          type:'Actions',
          renderCell: (params) => {
            //console.log('params: >>',params.row);           
            return(
            <strong >    
              <ActionComp params={params.row} typeSQL={typeSQL} bEditUser={bEditUser} handleEditOpen={handleEditOpen} handleDelete={handleDelete} />                 
            </strong> )      
          }    
      },
      
    ]);

    const ActionComp =(props)=>{

      const [myRec,setMyRec] = useState(props.params);
      const [bEdit,setbEdit] = useState(props.bEditUser);
      const [openDialog, setOpenDialog] = React.useState(false);    
      const loginRedux = useSelector( (state) =>state.user.blogin);   
      const dispatch = useDispatch();
      const clientUUIDRedux = useSelector( (state) =>state.user.clientUUID);  
      const serverUUIDRedux = useSelector( (state) =>state.user.serverUUID); 
      const serverRedux= useSelector( (state) =>state.server.addres);        
      const portRedux= useSelector( (state) =>state.server.port);
  
      const navigate = useNavigate();      


      const handleClickOpenDialog = () => {
        setOpenDialog(true);
      };
    
      const handleCloseDialogNO = () => {
        setOpenDialog(false);
      };
      const handleCloseDialogYES = () => {
        console.log('handleCloseDialogYES >>>', myRec);
        setOpenDialog(false);
        deleteUser(myRec.id,props);
        
      };      
    
      const deleteUser = async (id,props) => {      
        console.log(' deleteUser id >>>',id);
        const body = JSON.stringify({clientUUID:clientUUIDRedux, serverUUID:serverUUIDRedux,typeSQL:'delete', id:id, });
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        const requestOptions = {
          method: 'POST',
          headers: myHeaders,
          mode:'cors',
          credentials:'omit',
          body: body,
      };        
        const addr = serverRedux+":"+portRedux+"/api/user";
        await fetch(addr,requestOptions)
       .then((response) => response.json())
       .then((data) => {          
         console.log(' data >>',data);
         if (data.rows>0){
          props.handleDelete();
            //setStatus('');
            //hClose();
         }else {
            //setStatus('Ошибка внесения данных !!!');
         }
       })      
       .catch((error) =>{
        console.log("Ошибка обновления данных: %s", error);
        //setStatus('Ошибка сервера !!!');
       })
    };
        
        
      useEffect(() => {
        setbEdit(props.bEditUser);
        setRecUser(props.params);
//        console.log('Action Comp >> ',props.params);
      },[props.params,props.bEditUser] );
      
const hEdit= ()=>{
    props.handleEditOpen(myRec);
    setbEdit(true);
}

if(!loginRedux) return <Navigate to="/" replace/>;

      return (
        <>              
                 <IconButton  disabled={ bEdit ? true : false } >
                    <EditIcon color= {!bEdit ? "success" : "default"}   onClick={hEdit} />
                  </IconButton>                                      
                  <IconButton  disabled={ bEdit ? true : false } >                                      
                    <DeleteIcon  color = {!bEdit ? "success" : "default"}  onClick={handleClickOpenDialog} />
                  </IconButton >  
      <Dialog
        open={openDialog}
        onClose={handleCloseDialogNO}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <u>{"Подтверждение"} </u>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Удалить Запись
            <Box> пользователь  {props.params.login} </Box>
            <Box> id  {props.params.id} ?</Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton>
            <DangerousIcon color="error" size="large" onClick={handleCloseDialogNO} />
          </IconButton>
          <IconButton>
          <DoneAllIcon  color="success" size="large" onClick={handleCloseDialogYES} />
          </IconButton>
        </DialogActions>
      </Dialog>                                  

        </>
      )
    }

    const handleEditOpen =(e)=>{            
      console.log('handleEditOpen recUser >>> ',e);              
      setTypeSQL('update');
      setRecUser(e);
      setEditUser(true);
      
    }
    const handleEditClose =(e)=>{
      getApiData();
      setEditUser(false);
      setTypeSQL('update');      
    }

    const handleDelete =(params)=>{
      console.log('handleDelete  recUser >>> ',params);            
      getApiData();

    }

    const handleRowClick = (params) => {
      console.log('handleRowClick  >>> ',params);
      setRecUser(params.row);      
        
      
      //setMessage(`Movie "${params.row.login}" clicked`);
    };    

    const handleAddClick = (params) => {
      setTypeSQL('insert');
      setEditUser(true);
      setRecUser({});
      //setMessage(`Add "${params.row.login}" clicked`);
    };    
        
     const getApiData = async () => {
        console.log("getApiDate start ...");      
        const body = JSON.stringify({clientUUID:clientUUIDRedux, serverUUID:serverUUIDRedux});
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        const requestOptions = {
          method: 'POST',
          headers: myHeaders,
          mode:'cors',
          credentials:'omit',
          body: body,
      };    
         const addr = serverRedux+":"+portRedux+"/api/users";        
         await fetch(addr,requestOptions)
        .then((response) => response.json())
        .then((data) => {                              
          console.log('getapiData end >>> ',data.length);          
          if ((data instanceof Object) && (Array.isArray(data))){          
            setUsers(data);
            setWait(false);
          } else  {
            dispatch(setLoginParams(false));            
            setUsers([]);
            setWait(false);

          }
        })
        .catch((error) => {
          console.error("Ошибка получения данных:", error);
        });
    }
    const hasMounted = useRef(false);      

    React.useEffect(() => {
        console.log("USERS CreateWindow");
        if(!hasMounted.current){
          hasMounted.current=true;
          getApiData();
        }      
      },[]);
    
      if(!loginRedux) return <Navigate to="/" replace />;  

    return (
      <>
      { !bWait ? (
        
    <Box spacing={1} sx={{ width: '100%' }} >
    <Box sx={{ pt:1,}}>
      <Tooltip title="Добавть пользователя">
        <IconButton onClick={handleAddClick} disabled={ bEditUser ? true : false }  color="success" aria-label="add an alarm">
          <PersonAddIcon />   
        </IconButton>  
      </Tooltip>  
      <DataGrid sx={{mt:1,}}
       disableRowSelectionOnClick
        rows={users}
        columns={colDefs}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        checkboxSelection
        onRowClick={handleRowClick}  
        
      />
    </Box>
    {message && <Alert severity="info">{message} 
        </Alert>}
    {bEditUser && <EditUser recUser={recUser} typeSQL={typeSQL}  handleEditClose={handleEditClose}/> }   
    </Box>
      ) : (
        <Box sx={{ display: 'flex', justifyContent:'center', alignItems:'center' }}>
            <CircularProgress />
          </Box>
        )}
    </>
  )
}