import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice.js';
import serverReducer from './serverSlice.js';


export default configureStore({
  reducer:{
    user:userReducer,
    server:serverReducer,
  },  
});



